(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/config/menu-config/views/user-menu-header.js') >= 0) return;  svs.modules.push('/components/config/menu-config/views/user-menu-header.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.config=_svs.config||{};
_svs.config.menu_config=_svs.config.menu_config||{};
_svs.config.menu_config.templates=_svs.config.menu_config.templates||{};
svs.config.menu_config.templates.user_menu_header = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "Logga ut";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"padding-xs-v-1 padding-xs-h-2 bg-white f-content\">\n  <div class=\"padding-bottom-1 f-bold\">\n    Inloggad som:\n  </div>\n  <div class=\"padding-bottom-1\">\n    "
    + alias4(((helper = (helper = lookupProperty(helpers,"printUserAlias") || (depth0 != null ? lookupProperty(depth0,"printUserAlias") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"printUserAlias","hash":{},"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":22}}}) : helper)))
    + "\n  </div>\n  <div class=\"padding-bottom-1 f-bold\">\n    Behörighet:\n  </div>\n  <div class=\"padding-bottom-1 capitalized\">\n    "
    + alias4(((helper = (helper = lookupProperty(helpers,"printUserRole") || (depth0 != null ? lookupProperty(depth0,"printUserRole") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"printUserRole","hash":{},"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":12,"column":21}}}) : helper)))
    + "\n  </div>\n  <div class=\"margin-top-2\">\n    "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"href":(lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"logout",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":15,"column":107},"end":{"line":15,"column":128}}}),"class":"navigation-user-login margin-xs-bottom-1","block":true,"inverted":true,"size":300},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":15,"column":145}}})) != null ? stack1 : "")
    + "\n  </div>\n</div>\n";
},"useData":true});
Handlebars.partials['components-config-menu_config-user_menu_header'] = svs.config.menu_config.templates.user_menu_header;
})(svs, Handlebars);


 })(window);