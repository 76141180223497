(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/config/menu-config/views/user-menu-header-logged-out.js') >= 0) return;  svs.modules.push('/components/config/menu-config/views/user-menu-header-logged-out.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.config=_svs.config||{};
_svs.config.menu_config=_svs.config.menu_config||{};
_svs.config.menu_config.templates=_svs.config.menu_config.templates||{};
svs.config.menu_config.templates.user_menu_header_logged_out = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    return "Logga in";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"padding-xs-v-1 padding-xs-h-2 bg-white\">\n  <div class=\"padding-xs-v-4 f-center\">\n    "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"icon",{"name":"ui","hash":{"size":"600","icon":"user"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":48}}})) != null ? stack1 : "")
    + "\n  </div>\n  <div class=\"padding-bottom-1\">\n    "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"href":(lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"loginHome",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":6,"column":107},"end":{"line":6,"column":131}}}),"class":"navigation-user-login margin-xs-bottom-1","block":true,"inverted":true,"size":300},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":148}}})) != null ? stack1 : "")
    + "\n  </div>\n</div>\n";
},"useData":true});
Handlebars.partials['components-config-menu_config-user_menu_header_logged_out'] = svs.config.menu_config.templates.user_menu_header_logged_out;
})(svs, Handlebars);


 })(window);